<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-icon left>mdi-connection</v-icon>

        <span class="text-h6 font-weight-light">Service connection</span>
      </v-col>
    </v-row>
    <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="environnements"
        item-text="environment" 
        label="Environment"
        dense
        required 
        disabled
        v-model="serviceConnection.environment"
        :rules="environmentRules"
      ></v-select>
      <!-- <v-text-field label="Subscription ID"></v-text-field> -->
      <v-text-field
        label="Name"
        required
        v-model="serviceConnection.name"
        :rules="nameRules"
      ></v-text-field>
      <v-text-field
        label="Tenant ID"
        required
        v-model="serviceConnection.tenantId"
        :rules="tenantIdRules"
      ></v-text-field>
      <v-text-field
        label="Client ID"
        required
        v-model="serviceConnection.clientId"
        :rules="clientIdRules"
      ></v-text-field>
      <v-text-field
        label="Client Secret"
        required
        :append-icon="show ?'mdi-eye':'mdi-eye-off'"
        v-model="serviceConnection.clientSecret"
        :rules="clientSecretRules"
        :type="show ? 'text' : 'password'"
        @click:append="show=!show"
      ></v-text-field>
      <v-row align="center" v-if="serviceConnectionId > 0">
        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="mr-4"
              @click="updateServiceConnection"
              block
              color="primary"
            >
              Update
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" v-if="serviceConnectionId > 0">
        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="mr-4"
              @click="deleteServiceConnection"
              block
              color="error"
            >
              Delete
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" v-if="serviceConnectionId == -1">
        <v-col cols="12">
          <div class="text-center">
            <v-btn class="mr-4" @click="createServiceConnection" block>
              Create
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ServiceConnectionsService from "../../services/ServiceConnectionsService";

export default {
  name: "ServiceConnection",
  props: { serviceConnectionId: Number },
  components: {},
  data: () => ({
    environnements: [
      {environment :'Azure Cloud'},
      // {environment :'Azure China Cloud'}
      // {environment :"Azure China Cloud"},
      // {environment :"Azure US Government"},
      // {environment :"Azure German Cloud"},
    ],
    serviceConnection:  {environment :'Azure Cloud'},
    valid: true,
    environmentRules: [(v) => !!v || "Environement is required"],
    nameRules: [(v) => !!v || "Name is required"],
    tenantIdRules: [(v) => !!v || "TenantID is required"],
    clientIdRules: [(v) => !!v || "ClientID is required"],
    clientSecretRules: [(v) => !!v || "Client Secret is required"],
    serviceConnectionsService: null,
    show: false
  }),
  created() {
    this.serviceConnectionsService = new ServiceConnectionsService(
      this.$route.params.accountName
    );
  },
  methods: {
    async createServiceConnection() {
      const formIsValid = this.$refs.form.validate();

      if (!formIsValid) return;

      const response =
        await this.serviceConnectionsService.createServiceConnection(
          this.serviceConnection
        );

      this.$emit("close-drawer", response.ok);
    },
    async updateServiceConnection() {
      const formIsValid = this.$refs.form.validate();

      if (!formIsValid) return;

      const response =
        await this.serviceConnectionsService.updateServiceConnection(
          this.serviceConnection
        );

      this.$emit("close-drawer", response.ok);
    },
    async deleteServiceConnection() {
      const response =
      await this.serviceConnectionsService.deleteServiceConnection(
        this.serviceConnection.id
      );

      this.$emit("close-drawer", response.ok);
    },
  },
  watch: {
    serviceConnectionId: async function (newVal) {
      // Affichage du composant en mode création
      if (newVal == -1) {
        this.serviceConnection = {environment :'Azure Cloud'};
        return;
      }

      // Affichage du composant en mode édition
      const response =
        await this.serviceConnectionsService.getServiceConnection(newVal);
        this.serviceConnection = await response.json();
      },
  },
};
</script>